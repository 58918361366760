.section_title__container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section_title__title {
  text-transform: uppercase;
  font-size: 14px;
  font-variant: proportional-nums;
  line-height: 20px;
  color: #1A1F36;
}

.section_title__container__divider {
  margin: 16px 0;
}

.section__balance__amount_card_title {
  text-transform: uppercase;
  font-size: 14px;
  font-variant: proportional-nums;
  line-height: 20px;
  color: #8c8c8c;
  display: block;
}

.section__balance__amount {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  color: #1A1F36;
  display: block;
  margin-top: 12px;
}

.section__balance__amount {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  color: #1A1F36;
  display: block;
  margin-top: 12px;
}

.section_balance__info_card {
  width: 300px;
}

.section_balance__percents_card {
  margin-left: 12px;
}

.section__balance__withdraw_btn {
  margin-top: 32px;
  width: 100%;
  font-size: 16px;
  height: 100%;
}

.section__withdrawal_history__container {
  display: grid;
  grid-template-columns: fit-content(150px) fit-content(150px) fit-content(150px) auto;
  row-gap: 24px;
  column-gap: 64px;
  overflow-x: hidden;
  min-width: max-content;
}

.section__links__container {
  align-items: center;
  display: grid;
  grid-template-columns: fit-content(240px) fit-content(150px) auto;
  row-gap: 24px;
  column-gap: 64px;
  overflow-x: hidden;
  min-width: max-content;
}

.section__links__container_link {
  border-bottom: 1px dotted;
  cursor: pointer;
  user-select: none;
}

.section__withdrawal_history__tag {
  padding: 1px 6px;
  /*border: 1px solid;*/
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  width: fit-content;
  font-weight: 600;
}

.section__withdrawal_history__tag_positive {
  color: #006908;
  background-color: #d7f7c2;
  /*border-color: #a6eb84;*/
}

.section__withdrawal_history__tag_negative {
  color: #b3093c;
  background-color: #ffe7f2;
  /*border-color: #ffccdf;*/
}

.section__withdrawal_history__tag_default {
  color: #0055bc;
  background-color: #cff5f6;
  /*border-color: #ffccdf;*/
}

.section__percents_extra {
  margin-top: 32px;
  font-size: 16px;
  display: block;
}
