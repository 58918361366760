.layout__container {
  min-height: 100vh;
}

.layout__container__col {
  padding: 128px 64px 40px;
}

.layout__container__left_side {
  border-right: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.layout__container__left_side__title {
  max-width: 292px;
}

.layout__container__left_side__link {
  margin: 32px 0 0 0;
  font-size: 14px;
  color: #000;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  outline: none;
  padding: 0;
  transition: opacity .2s linear;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.layout__container__left_side__link:hover {
  opacity: .5;
  color: #000;
}

.layout__container__left_side__logo {
  object-fit: fill;
  width: 100px;
}

.layout__container__left_side__affilink_link {
  font-size: 12px;
}
